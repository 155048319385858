import "./import/blocks.js";

$(function(){
    // $(".nav-menu>ul>li:last").css("border-right", "none");
    $(".nav-menu__mobil>ul>li:last").css("border-bottom", "none");
    $(".nav-menu__mobil>ul>li:first").css("border-top", "1px solid #265f9447");
    $(".nav-menu__mobil>ul>li:first").css("padding-top", "20px");

    $(".burger, .close-mob-men").click(function(){
        event.preventDefault();
        $(".nav-menu__mobil").toggleClass("mobil-menu");
    });


    $(".slider").slick({
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 500,
        fade: true,
        cssEase: "linear"
    });
  
    $(".noactive").click(function(){
  	event.preventDefault();
    });

    $(".bulit__header>div:last").css("margin-right", "0");

});